import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import keys from 'lodash/keys'
import {fulfillmentStatusTypes} from 'app/constant'
import {showMessage} from 'app/redux/messageSlice'
import {setTotalCount} from 'app/redux/pagingSlice'
import ordersService from 'app/services/OrdersService'
import {eventNames} from 'app/constant'
import eventPublishService from 'app/services/EventPublishService'
import {financialStatusTypes} from 'app/utilities'
import {formatYYYYMMDDLastNDays, toDateStartOfUTC, toDateEndOfUTC} from 'app/format/date'
import cloneDeep from 'lodash/cloneDeep'
import {setCurrentPageOne} from 'app/components/pagination/PagingWithFilterAction'
import {RootState} from 'setup'

const initialFilter: any = {
  offset: 0,
  name: null,
  orderBy: null,
  financialStatus: null,
  fulfillmentStatus: null,
  orderNumber: null,
  deliveryStatus: null,
  orderType: null,
  pageSize: 10,
  wineClubId: null,
  stateId: null,
  zipCode: null,
  totalWeight: null,
  bottles: null,
  location: null,
  fromDate: null,
  toDate: null,
  currentPage: 1,
  canLoad: false,
  selectionType: null,
}

export const getOrders = createAsyncThunk(
  'orders/list',
  async (model: any, {dispatch, getState}) => {
    try {
      dispatch(setFetching(true))
      const filter = model?.filter
      const response = await ordersService.getOrders(mapToOrdersFilter(filter), model?.signal)
      dispatch(setTotalCount(response.totalCount))
      return response
    } finally {
      if (!model?.signal?.aborted) {
        dispatch(setFetching(false))
      }
    }
  }
)

export const getOrdersTotalCount = async (filter: any, dispatch: any, order: any) => {
  const {orders, isFilterChanged} = order
  const {data, totalCount} = orders
  if ((data && data.length === 0) || isFilterChanged) {
    dispatch(setFetching(true))
    const totalCount = await ordersService.getOrdersTotalCount(filter)
    dispatch(setTotalCount(totalCount))
    dispatch(setIsFilterChanged(false))
    return totalCount
  }
  return totalCount
}

export const getUnFulfilledOrderOrders = createAsyncThunk(
  'orders/list',
  async (model: any, {dispatch, getState}) => {
    try {
      dispatch(setFetching(true))
      const filter = model?.filter
      const param = mapToUnFulfilledOrderFilter(filter)
      const response = await ordersService.getOrders(param, model?.signal)
      dispatch(setTotalCount(response.totalCount))
      return response
    } finally {
      if (!model?.signal?.aborted) {
        dispatch(setFetching(false))
      }
    }
  }
)

export const cancelOrder = createAsyncThunk(
  'cancel/order',
  async ({orderId, filter, offset}: any, {dispatch}: any) => {
    dispatch(setSubmitting(true))
    try {
      const res = await ordersService.cancelOrder(orderId)
      if (res.status === 'success') {
        dispatch(showMessage({text: 'Order cancelled successfully', variant: 'success'}))
        await eventPublishService.publish({message: {id: orderId}, name: eventNames.orderCancel})
      } else {
        dispatch(showMessage({text: res.errors, variant: 'error'}))
      }
    } catch (error) {
      console.error(error)
      dispatch(showMessage({text: 'An error occurred while cancelling order', variant: 'error'}))
    } finally {
      dispatch(setSubmitting(false))
      dispatch(setShowDialog(false))
      dispatch(setIsFilterChanged(true))
      const _filter = {...filter, pageSize: 10, offset}
      dispatch(getOrders({filter: _filter}))
    }
  }
)

const initialState = {
  orders: {
    data: [],
    totalCount: 0,
  },
  showDialog: false,
  isSubmitting: false,
  filter: initialFilter,
  isFetching: true,
  orderId: '',
  viewPackagePageIndex: 0,
  isFilterChanged: false,
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.isFilterChanged = true
      state.filter = {...state.filter, ...action.payload}
      setCurrentPageOne(state)
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload
    },
    setPagingSizeChange: (state, action) => {
      state.filter = {...state.filter, ...action.payload}
    },
    setOrderBy: (state, action) => {
      state.filter.orderBy = action.payload
      state.filter.currentPage = 1
      state.filter.offset = 0
    },
    setIsFilterChanged: (state, action) => {
      state.isFilterChanged = action.payload
    },
    setShowDialog: (state, action) => {
      state.showDialog = action.payload
    },
    setSubmitting: (state, action) => {
      state.isSubmitting = action.payload
    },
    setOrderId: (state, action) => {
      state.orderId = action.payload
    },
    setNavigationPageIndex: (state, action) => {
      state.viewPackagePageIndex = action.payload
    },
    resetOrderState: () => initialState,
  },
  extraReducers: {
    [getOrders.fulfilled.toString()]: (state, action) => {
      state.orders = action.payload
      state.isFetching = false
    },
  },
})

export const {
  setFilter,
  setFetching,
  setOrderBy,
  setShowDialog,
  setSubmitting,
  setOrderId,
  resetOrderState,
  setNavigationPageIndex,
  setIsFilterChanged,
  setPagingSizeChange,
} = ordersSlice.actions

export default ordersSlice.reducer

//Orders

export const selectOrdersTotalCount = ({orders}: RootState): number =>
  orders.order.orders.totalCount

export const selectOrders = ({orders}: RootState): any[] => orders.order.orders.data

export const selectIsSubmitting = ({orders}: RootState): boolean => orders.order.isSubmitting

export const selectIsFetching = ({orders}: RootState): boolean => orders.order.isFetching

export const selectShowDialog = ({orders}: RootState): boolean => orders.order.showDialog

export const selectFilter = ({orders}: RootState): any => orders.order.filter

//misc - orders

export const selectPendingOrdersTotalCount = ({misc}: RootState): number =>
  misc.order.orders.totalCount

export const selectPendingOrders = ({misc}: RootState): any[] => misc.order.orders.data

export const selectPendingOrderIsSubmitting = ({misc}: RootState): boolean =>
  misc.order.isSubmitting

export const selectPendingOrderIsFetching = ({misc}: RootState): boolean => misc.order.isFetching

export const selectPendingOrderShowDialog = ({misc}: RootState): boolean => misc.order.showDialog

export const selectPendingOrderFilter = ({misc}: RootState): any => misc.order.filter

export const mapToUnFulfilledOrderFilter = (filter: any) => {
  const param: any = cloneDeep(filter || {...initialFilter})
  if (param.bottles) {
    param.bottles = parseInt(filter.bottles)
  }
  if (param.totalWeight) {
    param.totalWeight = filter.totalWeight
  }

  param.fromDate = formatYYYYMMDDLastNDays(90)

  param.fulfillmentStatus = fulfillmentStatusTypes.unfulfilled
  param.financialStatus = financialStatusTypes.paid

  return param
}

const mapUnFulfilledOrderTotalCountFilter = (filter: any) => {
  const param: any = cloneDeep(filter || {...initialFilter})
  param.fulfillmentStatus = fulfillmentStatusTypes.unfulfilled
  param.financialStatus = financialStatusTypes.paid
  param.fromDate = formatYYYYMMDDLastNDays(90)
  if (param.bottles) {
    param.bottles = parseInt(param.bottles)
  }
  return param
}

const mapOrderTotalCountFilter = (filter: any) => {
  const param: any = cloneDeep(filter || {...initialFilter})
  if (param.fromDate) {
    param.fromDate = toDateStartOfUTC(filter.fromDate)
  }
  if (param.toDate) {
    param.toDate = toDateEndOfUTC(filter.toDate)
  }
  if (param.bottles) {
    param.bottles = parseInt(param.bottles)
  }

  return param
}

const mapToOrdersFilter = (filter: any) => {
  const param = cloneDeep(filter || initialFilter)
  if (param.bottles) {
    param.bottles = parseInt(filter.bottles)
  }
  if (param.totalWeight) {
    param.totalWeight = filter.totalWeight
  }

  if (param.fromDate && param.toDate) {
    param.fromDate = toDateStartOfUTC(param.fromDate)
    param.toDate = toDateEndOfUTC(param.toDate)
  }

  return param
}
