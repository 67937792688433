import {FC, useMemo} from 'react'
import {gql, useQuery} from '@apollo/client'
import {Select} from '_core/forms'
import filter from 'lodash/filter'
import {SimplePage} from '_core'
import {GET_COUNTRIES} from 'app/services/LookupsService/graphql'
import find from 'lodash/find'
import {countryName} from 'app/constant/address'

const GET_STATES = gql`
  query GetStates {
    states(orderBy: NAME_ASC) {
      nodes {
        id
        name
        code
        countryId
      }
    }
  }
`

type Props = {
  name: string
  onChange?: any
  countryId?: string
  className?: string
  disabled?: boolean
}

const States: FC<Props> = ({className, name, countryId, onChange, disabled}) => {
  const {data, loading, error} = useQuery(GET_STATES)
  const {data: countries} = useQuery(GET_COUNTRIES)

  const label = useMemo(() => {
    if (countries?.countries?.nodes) {
      const country = find(countries?.countries?.nodes, ({id}) => id === countryId)
      if (country?.name && country.name === countryName.CA) {
        return 'Province'
      }
    }
    return 'State'
  }, [countryId, countries])

  return (
    <SimplePage loading={loading} error={error}>
      <div className={className}>
        <label className='form-label fs-6 fw-bold text-dark required'>{label}</label>
        <Select id='sltSelectState' name={name} onChange={onChange} disabled={disabled}>
          <option value=''>Select {label}</option>
          {data &&
            countryId &&
            filter(data.states.nodes, {countryId}).map((state: any) => (
              <option value={state.id} key={state.id}>
                {state.name}
              </option>
            ))}
          {data &&
            !countryId &&
            data.states.nodes.map((state: any) => (
              <option value={state.id} key={state.id}>
                {state.name}
              </option>
            ))}
        </Select>
      </div>
    </SimplePage>
  )
}

export default States
