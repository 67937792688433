import {FC, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import map from 'lodash/map'
import {RootState} from 'setup'
import Card from '_core/components/Card'
import {getCustomerAddresses} from 'app/features/customers/redux/customerSlice'
import {useParams} from 'react-router-dom'
import AddressListItem from './AddressListItem'
import AddressForm from '../AddressForm'
import {useFormikContext} from 'formik'
import ConfirmationDialog from '_core/components/ConfirmationDialog'
import CustomerService from 'app/services/CustomerService'
import {showMessage} from 'app/redux/messageSlice'

type Props = {}
const AddressList: FC<Props> = () => {
  const [address, setAddress] = useState<any>({
    create: false,
    delete: false,
    id: null,
  })
  const [isSubmitting, setIsSubmitting] = useState(false)
  const dispatch = useDispatch()

  const {
    values: {firstName, lastName, email, phone},
  } = useFormikContext<any>()

  const addressList = useSelector<RootState>(
    ({customers}) => customers.customer.customerAddress
  ) as any[]

  const {id} = useParams<any>()

  useEffect(() => {
    if (id) {
      dispatch(getCustomerAddresses(id))
    }
  }, [dispatch, id])

  const handleClose = () => {
    setAddress({create: false, delete: false})
  }

  const handleOpen = () => {
    setAddress({create: true})
  }

  const handleDeleteDialog = (id: any) => {
    setAddress({delete: true, id})
  }

  const handleOpenDialog = (id: any) => {
    setAddress({create: true, id})
  }

  const handleDeleteAddress = async () => {
    try {
      setIsSubmitting(true)
      await CustomerService.deleteAddress(address.id)
      setAddress({delete: false})
      dispatch(getCustomerAddresses(id))
      dispatch(
        showMessage({
          text: 'Customer Address has been deleted successfully ',
          variant: 'success',
        })
      )
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while delete address ',
          variant: 'error',
        })
      )
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleUpdateAddress = async (addressId: string) => {
    try {
      await CustomerService.updateAddress({id: addressId, isPrimary: true})
      dispatch(getCustomerAddresses(id))
      dispatch(
        showMessage({
          text: 'Customer Address has been primary set successfully ',
          variant: 'success',
        })
      )
    } catch (error) {
      console.log(error)
      dispatch(
        showMessage({
          text: 'An error occurred while set primary address ',
          variant: 'error',
        })
      )
    }
  }

  const customerData = {
    firstName,
    lastName,
    email,
    phone,
    customerId: id,
  }

  return (
    <Card
      title='Shipping Addresses'
      className='card-xl-stretch mb-5 mb-xl-8'
      toolbar={
        addressList?.length ? (
          <button
            id='btnAddPayment'
            type='button'
            onClick={handleOpen}
            className='btn btn-sm btn-secondary '
          >
            Add new address
          </button>
        ) : null
      }
    >
      <div className='py-4'>
        <div className='row'>
          {addressList &&
            map(addressList, (address: any) => (
              <AddressListItem
                key={address.id}
                address={address}
                onDeletePopUp={handleDeleteDialog}
                onUpdatePopUp={handleOpenDialog}
                onSelect={handleUpdateAddress}
              />
            ))}
          {addressList?.length === 0 && (
            <div className='card notice d-flex bg-light rounded border-secondary border border-dashed '>
              <div className='d-flex justify-content-center m-8 p-6'>
                <div>
                  <div className='mb-3'>No address found. Create your first address !</div>
                  <span className='d-flex justify-content-center'>
                    <button
                      id='btnAddPayment'
                      type='button'
                      onClick={handleOpen}
                      className='btn explore-btn-outline btn-sm  '
                    >
                      Add new address
                    </button>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {address.create && (
        <AddressForm
          onClose={handleClose}
          customerId={id}
          customerOverView={customerData}
          addressId={address.id}
        />
      )}

      <ConfirmationDialog
        show={address.delete}
        title='Delete Address'
        message='Are you sure to delete this address?'
        onClose={handleClose}
        onConfirm={handleDeleteAddress}
        isSubmitting={isSubmitting}
      />
    </Card>
  )
}
export default AddressList
