import omit from 'lodash/omit'
import config from 'config'
import {toAbsoluteUrl} from '_metronic/helpers'

export * from './array'
export * from './model-mapper'

export const getProductImageUrl = (image: any) => {
  return `${config.API_URL}/users/v1/products/${image.id}/image/${encodeURIComponent(
    image.src
  )}/public`
}

export const financialStatusTypes = {
  paid: 'PAID',
  failed: 'FAILED',
  paymentFailed: 'PAYMENT_FAILED',
  partiallyRefunded: 'PARTIALLY_REFUNDED',
  unPaid: 'PENDING',
  require3d: 'REQUIRE_THREE_D_ACTION',
}

export const getStoreLogoUrl = (image: any) => {
  const {id, storeLogo} = image
  if (storeLogo) {
    return `${config.API_URL}/users/v1/public/store/${id}/image/${encodeURIComponent(
      storeLogo
    )}/public`
  }
  return toAbsoluteUrl('/media/icons/duotune/ecommerce/ecm004.svg')
}
export const getStoreNotificationBannerUrl = (image: any) => {
  const {id, storeLogo} = image
  if (storeLogo) {
    return `${config.API_URL}/users/v1/public/store/${id}/image/${encodeURIComponent(
      storeLogo
    )}/public`
  }
  return ''
}

export const cleanModel = (model: any) => {
  return omit(model, ['__typename'])
}

export const getTimezone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const getTimeOffset = () => {
  const now = new Date()
  const formatter: any = new Intl.DateTimeFormat([], {timeZoneName: 'short'})
  if (formatter) {
    return formatter.formatToParts(now).find((part: any) => part.type === 'timeZoneName').value
  }

  const timezoneOffsetMinutes = now.getTimezoneOffset()

  const offsetHours = Math.floor(Math.abs(timezoneOffsetMinutes) / 60)
  const offsetMinutes = Math.abs(timezoneOffsetMinutes) % 60

  return (
    (timezoneOffsetMinutes > 0 ? '-' : '+') +
    String(offsetHours).padStart(2, '0') +
    ':' +
    String(offsetMinutes).padStart(2, '0')
  )
}

export const mapToShippingRate = (shippingMethod: any) => {
  return {
    serviceId: shippingMethod.serviceId,
    price: shippingMethod.rate,
    title: shippingMethod.serviceName,
    serviceType: shippingMethod.serviceType,
    carrierType: shippingMethod.carrierType,
    originalPrice: shippingMethod?.originalRate || 0,
    discount: shippingMethod?.discount || 0,
    handlingFee: shippingMethod?.handlingFee,
    providerShippingPrice: shippingMethod?.providerShippingPrice,
  }
}
