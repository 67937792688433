import featureFlagService from 'app/services/FeatureFlagService'
import {FC, useEffect, useState} from 'react'
//comment

type Props = {
  flagKey: string
  children: any
  defaultChildren?: any
}

const FeatureFlag: FC<Props> = ({flagKey, children, defaultChildren}) => {
  const [isFlagEnabled, setIsFlagEnabled] = useState(false)
  const [isFetching, setIsFetching] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      try {
        setIsFetching(true)
        const isFlagEnabled = await featureFlagService.evaluation(flagKey)
        setIsFlagEnabled(isFlagEnabled)
      } catch (error) {
        console.error(error)
      } finally {
        setIsFetching(false)
      }
    }
    if (flagKey) {
      fetch()
    }
  }, [flagKey])

  if (isFetching) {
    return null
  }

  if (isFlagEnabled) {
    return <>{children}</>
  }

  if (defaultChildren) {
    return <>{defaultChildren}</>
  }

  return null
}

export default FeatureFlag
