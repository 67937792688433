import React, {useMemo} from 'react'
import {AsideMenuItemWithAction} from '../AsideMenuItemWithAction'
import {RootState} from 'setup'
import {useSelector} from 'react-redux'
import config from 'config'
import {AsideMenuItem} from '../AsideMenuItem'
import {paymentProviderTypes} from 'app/constant'
import FeatureFlag from 'app/components/FeatureFlag'
import {flagKey} from 'app/constant/flag-key'

interface Props {
  menuItem: {
    code: string
    displayOrder: number
    icon: any
    id: string
    menuId: string
    name: string
    url: string
  }
}

const code = {
  pos_terminal: 'pos_terminal.all',
  open_pos: 'open_pos.all',
}

const ChannelMenuItem: React.FC<Props> = ({menuItem}) => {
  const store = useSelector<RootState, any>(({auth}) => auth.user?.store)

  const url = useMemo(() => {
    const host = store && store.domain ? `https://${store.domain}.` : 'https://'
    const baseURLs: any = {
      production: 'pos.corksy.io',
      staging: 'pos-staging.corksy.io',
      demo: 'pos-demo.corksy.io',
      dev: 'localhost',
    }

    return `${host}${baseURLs[config.ENVIRONMENT]}`
  }, [store])

  return useMemo(() => {
    const handleNavigatePOS = () => {
      window.open(url, '_blank')
    }

    if (menuItem.code === code.pos_terminal) {
      if (store?.paymentProviderType === paymentProviderTypes.elavon) {
        return (
          <FeatureFlag flagKey={flagKey.payConnect}>
            <AsideMenuItem
              id={`link${menuItem.name}`}
              to={menuItem.url}
              title={menuItem.name}
              hasBullet={true}
            />
          </FeatureFlag>
        )
      }
      return null
    }

    if (menuItem.code === code.open_pos) {
      return (
        <AsideMenuItemWithAction
          id='linkOpenPos'
          onClick={handleNavigatePOS}
          title='Open POS'
          hasBullet={true}
        />
      )
    }

    return (
      <AsideMenuItem
        id={`link${menuItem.name}`}
        to={menuItem.url}
        title={menuItem.name}
        hasBullet={true}
      />
    )
  }, [menuItem.code, menuItem.name, menuItem.url, store?.paymentProviderType, url])
}

export default React.memo(ChannelMenuItem)
